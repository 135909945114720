.heroarena
  @extend .jumbotron

  padding-top: 100px
  background: none

  .secondary
    display: block
    color: #777
    font-size: $jumbotron-font-size * 0.8
  small.secondary
    font-size: 35%

  .actions
    @extend .list-inline

    margin-top: $jumbotron-padding * 2

  > *
    text-align: center
