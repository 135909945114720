
.form-control.form-control-inline
  padding-left: 0
  border-color: transparent
  box-shadow: none

.input-group-addon.btn
  padding: 0
  border: 0

  > .btn
    border-top-left-radius: 0
    border-bottom-left-radius: 0
