html.page-home
  &, body
    height: 100%
    min-height: 550px

.page-home
  body
    position: relative

  .heroarena
    margin-bottom: 60px
    border: 0

    h1
      margin-top: 0

    .secondary
      margin-top: 30px

  nav
    text-align: center
    position: absolute
    width: 100%
    bottom: 100px

    a
      color: #777

    .list-inline > li
      $padding: 15
      padding-left: $padding
      padding-right: $padding
