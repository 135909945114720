$icon-font-path: '../fonts/'

@import '../../node_modules/bootstrap-sass/assets/stylesheets/_bootstrap'

*
  box-sizing: border-box

.modal-backdrop
  bottom: 0

.btn-serious
  color: #000

  &:hover,
  &:focus
    color: #000

.container.loading
  background: transparent url('../images/loading.gif') no-repeat center center
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0

// Modules
@import 'modules/_heroarena'
@import 'modules/_form'

// Pages
@import 'pages/_home'
@import 'pages/_login'
@import 'pages/_diary'
